<template>
  <div class="custom-targeting-list-table">
    <div v-if="loader && localTargetings.length === 0" class="text-center">
      <Loader
        :size="'big'"
      />
    </div>
    <template v-else>
      <div class="table-responsive">
        <!--begin::Table-->
        <table class="table align-middle table-row-dashed fs-6 gy-5">
          <!--begin::Table head-->
          <thead>
            <!--begin::Table row-->
            <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
              <th class="min-w-100px">Nome</th>
              <th class="min-w-70px">Tipo</th>
              <th class="min-w-100px">Elementos</th>
              <th class="min-w-70px">Status</th>
              <th class="min-w-70px">Detalhes</th>
            </tr>
            <!--end::Table row-->
          </thead>
          <!--end::Table head-->
          <!--begin::Table body-->
          <tbody class="fw-semibold text-gray-600">
            <!--begin::Table row-->
            <tr v-for="(targeting, index) in localTargetings" :key="index">
              <td>
                <span>{{ targeting.name }}</span>
              </td>
              <td>
                <span>{{ targeting.type }}</span>
              </td>
              <td>
                <span>{{ targeting.value_count }}</span>
              </td>
              <td>
                <span>{{ targeting.status | formatStatus }}</span>
              </td>
              <td>
                <span>
                  <button
                    class="btn btn-sm btn-primary"
                    v-b-modal="`targeting-detail-modal-${targeting.id}`"
                  >
                    <i class="fas fa-eye px-0"></i>
                  </button>
                </span>
                <CustomTargetingDetailModal
                  :targeting="targeting"
                />
              </td>
            </tr>
            <!--end::Table row-->
          </tbody>
          <!--end::Table body-->
        </table>
      </div>
      <!--end::Table-->
      <div class="text-center mt-4">
        <Button
          class="btn-primary"
          :loading="loader"
          @click="loadMore"
          v-if="isLoadMoreVisible"
        >
          Carregar mais
        </Button>
      </div>
    </template>
  </div>
</template>

<script>

import CustomTargetingsService from '@/modules/custom-targetings/services/custom-targetings-service'

import Button from '@/components/common/Button/Button'
import CustomTargetingDetailModal from '@/components/custom-targetings/CustomTargetingDetailModal'
import Loader from '@/components/common/Loader/Loader'

export default {
  name: 'CreativeListTable',
  props: ['advertiserId', 'targetings', 'reload', 'types'],
  components: {
    Button,
    CustomTargetingDetailModal,
    Loader
  },
  data () {
    return {
      localTargetings: [],
      loader: false,
      page: 1,
      isLoadMoreVisible: true
    }
  },
  created () {
    if (this.targetings) {
      this.localTargetings = this.targetings
      this.isLoadMoreVisible = false
    } else {
      this.getTargetings()
    }
  },
  watch: {
    reload (newValue) {
      if (newValue) this.reset()
    }
  },
  methods: {
    /**
     * Get targetings
     */
    async getTargetings () {
      if (!this.advertiserId) return false

      this.loader = true
      try {
        const targetings = await CustomTargetingsService.getCustomTargetings({
          advertiserId: this.advertiserId,
          types: this.types,
          page: this.page
        })
        this.localTargetings = [...this.localTargetings, ...targetings.data]
        this.isLoadMoreVisible = targetings.meta.current_page !== targetings.meta.last_page
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    },
    /**
     * Handle retry error
     */
    handleRetryError (error) {
      error.handleGlobally && error.handleGlobally()
    },
    /**
     * Load more
     */
    loadMore () {
      this.page++
      this.getTargetings()
    },
    /**
     * Reset and reload
     */
    reset () {
      this.page = 1
      this.localTargetings = []
      this.getTargetings()
    }
  }
}
</script>
