<template>
  <b-modal
    :id="`targeting-detail-modal-${targeting.id}`"
    :modal-class="'default-modal'"
    header-class="justify-content-end"
    :hide-footer="true"
    @shown="shown"
    @hidden="hidden"
  >

    <template #modal-header="{ close }">
      <!--begin::Close-->
      <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close" @click="close()">
          <span class="svg-icon svg-icon-1">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
            </svg>
          </span>
      </div>
      <!--end::Close-->
    </template>

    <div class="text-center">
      <h2 class="highlighted-color1">
        <span>{{ localTargeting.name }}</span>
      </h2>
    </div>
    <AlertWraper
      :content="content"
      :type="content_type"
    />
    <div class="mt-4">
      <h3 class="font-size-medium mb-3">Dados gerais</h3>
      <p class="mb-4">
        <strong>Nome:</strong> {{ localTargeting.name }}<br/>
        <strong>Anunciante:</strong> {{ localTargeting.advertiser.name }}<br/>
        <strong>Tipo:</strong> {{ localTargeting.type | formatCustomTargetingType }}<br/>
        <strong>Elementos:</strong> {{ localTargeting.value_count }}<br/>
        <strong>Status:</strong> {{ localTargeting.status | formatStatus }}
      </p>
      <h3 class="font-size-medium mb-3">Valores</h3>
      <div v-if="loader && values.length === 0" class="text-center">
        <Loader
          :size="'big'"
        />
      </div>
      <ul v-else-if="values.length > 0">
        <li v-for="(value, index) in values" :key="index">
          {{ value.value }}
        </li>
      </ul>
    </div>
  </b-modal>
</template>

<script>

import CustomTargetingsService from '@/modules/custom-targetings/services/custom-targetings-service'
import { parseErrorResponseToArray } from '@/utils'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Loader from '@/components/common/Loader/Loader'

export default {
  name: 'CustomTargetingDetailModal',
  props: ['targeting'],
  components: {
    AlertWraper,
    Loader
  },
  data () {
    return {
      content: null,
      content_type: 'error',
      loader: false,
      localTargeting: '',
      values: []
    }
  },
  created () {
    this.localTargeting = this.targeting
  },
  computed: {
    valuesValues () {
      return this.values.map(item => {
        return item.value
      })
    }
  },
  methods: {
    /**
     * Get custom targeting
     */
    async getCustomTargeting () {
      this.loader = true
      try {
        const targeting = await CustomTargetingsService.getCustomTargeting(this.targeting.id)
        this.localTargeting = targeting
        this.values = targeting.values
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.loader = false
    },
    /**
     * Handle error
     */
    handleError (error) {
      this.content = parseErrorResponseToArray(error)
    },
    /**
     * Handle updated
     */
    handleUpdated (customTargeting) {
      this.reset()
    },
    /**
     * Hidden
     */
    hidden () {
      this.values = []
      this.content = null
    },
    /**
     * Reset
     */
    reset () {
      this.values = []
      this.content = null
      this.getCustomTargeting()
    },
    /**
     * Shown
     */
    shown () {
      this.getCustomTargeting()
    }
  }
}
</script>
