import axios from '@/plugins/axios'

/**
 * Get campaign
 */
const getCampaign = async (id) => {
  try {
    const response = await axios.get(`admin/campaigns/${id}`)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get campaigns
 */
const getCampaigns = async ({
  // eslint-disable-next-line camelcase
  advertiser_id = null,
  // eslint-disable-next-line camelcase
  amount_start = 0,
  // eslint-disable-next-line camelcase
  amount_end = 0,
  // eslint-disable-next-line camelcase
  ctr_start = 0,
  // eslint-disable-next-line camelcase
  ctr_end = 0,
  // eslint-disable-next-line camelcase
  end_date_end = null,
  // eslint-disable-next-line camelcase
  end_date_start = null,
  page = 1,
  platform = null,
  search = null,
  status = null,
  // eslint-disable-next-line camelcase
  start_date_end = null,
  // eslint-disable-next-line camelcase
  start_date_start = null
}) => {
  try {
    const params = {
      page: page
    }
    if (search) params.search = search
    // eslint-disable-next-line camelcase
    if (advertiser_id) params.advertiser_id = advertiser_id
    // eslint-disable-next-line camelcase
    if (amount_start > 0) params.amount_start = parseInt(amount_start * 100)
    // eslint-disable-next-line camelcase
    if (amount_end > 0) params.amount_end = parseInt(amount_end * 100)
    // eslint-disable-next-line camelcase
    if (ctr_start > 0) params.ctr_start = ctr_start
    // eslint-disable-next-line camelcase
    if (ctr_end > 0) params.ctr_end = ctr_end
    // eslint-disable-next-line camelcase
    if (end_date_end) params.end_date_end = end_date_end
    // eslint-disable-next-line camelcase
    if (end_date_start) params.end_date_start = end_date_start
    if (status) params.status = status
    if (platform) params.platform = platform
    // eslint-disable-next-line camelcase
    if (start_date_end) params.start_date_end = start_date_end
    // eslint-disable-next-line camelcase
    if (start_date_start) params.start_date_start = start_date_start

    const response = await axios.get('admin/campaigns', {
      params: params
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  getCampaign,
  getCampaigns
}
