import axios from '@/plugins/axios'

/**
 * Get custom targeting
 */
const getCustomTargeting = async (id) => {
  try {
    const response = await axios.get(`admin/custom-targetings/${id}`)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get custom targetings
 */
const getCustomTargetings = async ({
  advertiserId,
  types,
  page = 1
}) => {
  try {
    const response = await axios.get('admin/custom-targetings', {
      params: {
        advertiser_id: advertiserId,
        types: types,
        page: page
      }
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  getCustomTargeting,
  getCustomTargetings
}
