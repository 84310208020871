import axios from '@/plugins/axios'

/**
 * Get creative
 */
const getCreative = async (id) => {
  try {
    const response = await axios.get(`admin/creatives/${id}`)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get creatives
 */
const getCreatives = async ({
  // eslint-disable-next-line camelcase
  advertiser_id = null,
  advertiser = null,
  client = null,
  // eslint-disable-next-line camelcase
  end_date = null,
  page = 1,
  // eslint-disable-next-line camelcase
  start_date = null
}) => {
  try {
    const params = {
      page: page
    }
    // eslint-disable-next-line camelcase
    if (advertiser_id) params.advertiser_id = advertiser_id
    if (advertiser) params.advertiser_search = advertiser
    if (client) params.client_search = client
    // eslint-disable-next-line camelcase
    if (start_date) params.start_date = start_date
    // eslint-disable-next-line camelcase
    if (end_date) params.end_date = end_date
    const response = await axios.get('admin/creatives', {
      params: params
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  getCreative,
  getCreatives
}
