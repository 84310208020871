import axios from '@/plugins/axios'

/**
 * Get wallet
 */
const getWallet = async (id) => {
  try {
    const response = await axios.get(`admin/wallets/${id}`)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get wallets
 */
const getWallets = async ({
  // eslint-disable-next-line camelcase
  advertiser_id = null,
  // eslint-disable-next-line camelcase
  campaign_id = null,
  // eslint-disable-next-line camelcase
  company_id = null,

  page = 1
}) => {
  try {
    const params = {
      page: page
    }
    // eslint-disable-next-line camelcase
    if (advertiser_id) params.advertiser_id = advertiser_id
    // eslint-disable-next-line camelcase
    if (campaign_id) params.campaign_id = campaign_id
    // eslint-disable-next-line camelcase
    if (company_id) params.company_id = company_id

    const response = await axios.get('admin/wallets', {
      params: params
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  getWallet,
  getWallets
}
