import axios from '@/plugins/axios'

/**
 * Get advertiser
 */
const getAdvertiser = async (id) => {
  try {
    const response = await axios.get(`admin/advertisers/${id}`)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get advertisers
 */
const getAdvertisers = async ({
  // eslint-disable-next-line camelcase
  company_id = null,
  page = 1,
  search = null,
  // eslint-disable-next-line camelcase
  with_orders = false,
  // eslint-disable-next-line camelcase
  without_orders = false
}) => {
  try {
    const params = {
      page: page
    }
    if (search) params.search = search
    // eslint-disable-next-line camelcase
    if (company_id) params.company_id = company_id
    // eslint-disable-next-line camelcase
    if (with_orders) params.with_orders = 1
    // eslint-disable-next-line camelcase
    if (without_orders) params.without_orders = 1

    const response = await axios.get('admin/advertisers', {
      params: params
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get used coupons
 */
const getUsedCouponsByAdvertiser = async (id) => {
  try {
    const response = await axios.get(`admin/advertisers/${id}/coupons`)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  getAdvertiser,
  getAdvertisers,
  getUsedCouponsByAdvertiser
}
